import React from "react";

function Facebook({ className }) {
  return (
    <svg viewBox="0 0 73 71" fill="none" className={className}>
      <g clipPath="url(#clip0)">
        <path
          d="M0 35.477c.01-7.017 2.16-13.874 6.18-19.703C10.2 9.945 15.908 5.405 22.583 2.728A37.503 37.503 0 0143.681.736c7.081 1.377 13.583 4.764 18.682 9.732 5.1 4.968 8.569 11.294 9.969 18.178a34.546 34.546 0 01-2.105 20.495c-2.772 6.48-7.46 12.014-13.469 15.905-6.01 3.89-13.071 5.962-20.293 5.953-4.8.013-9.555-.899-13.99-2.68a36.458 36.458 0 01-11.855-7.703 35.285 35.285 0 01-7.896-11.538A34.468 34.468 0 010 35.476z"
          fill="#000"
        />
        <path
          d="M45.305 37.1l.917-6.854h-6.7v-4.729c-.017-.32.035-.638.153-.937.118-.298.3-.57.532-.795.233-.227.512-.403.82-.517.306-.115.635-.166.963-.15h4.35v-6.167h-6.09a7.899 7.899 0 00-5.502 2.214 7.454 7.454 0 00-2.28 5.347v5.757h-5.806v6.853h5.807V53.89h7.053V37.1h5.783z"
          fill="#C98049"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#C98049" d="M0 0H73V71H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;

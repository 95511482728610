import React from "react";

function CloseIcon({ className, onClick, id }) {
  return (
    <svg viewBox="0 0 34 34" className={className} id={id} onClick={onClick}>
      <path d="M1.444 1.444l31.112 31.112M32.556 1.444L1.444 32.556" />
    </svg>
  );
}

export default CloseIcon;

import React from "react";
import NavBar from "components/Navbar";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "./Footer";

export default function Layout(props) {
  const { children, path } = props;
  const { defaultPage } = useStaticQuery(graphql`
    query {
      defaultPage: pagesJson(pageName: { eq: "index" }) {
        ...OpenGraphFragment
      }
    }
  `);
  return (
    <>
      <Helmet
        titleTemplate="Lunsford Ridge Farm | %s"
        defaultTitle="Lunsford Ridge Farm"
      >
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="description" content={defaultPage.shareInfo.description} />
        <meta property="og:url" content="https://www.lunsfordridgefarm.com" />
        <meta
          property="og:description"
          content={defaultPage.shareInfo.description}
        />
        <meta
          property="og:image"
          content={`https://www.lunsfordridgefarm.com${defaultPage.shareInfo.image.childImageSharp.fixed.src}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
      </Helmet>
      <NavBar />
      <main id="main">{children}</main>
      <Footer />
    </>
  );
}

import React, { useRef, useEffect } from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import CloseIcon from "../icons/CloseIcon";
import MenuIcon from "../icons/MenuIcon";
import styles from "./Navbar.module.css";

export default function NavBar() {
  const menuRef = useRef(null);

  const setOpen = (shouldOpen, e) => {
    if (e) {
      e.stopPropagation();
    }
    const menu = document.getElementById("menu");
    const openStyle = styles.header__menuOpen;
    const isOpen = menu.classList.contains(openStyle);
    if (!isOpen && shouldOpen) {
      menu.classList.add(openStyle);
    } else if (isOpen) {
      menu.classList.remove(openStyle);
    }
  };

  const handleClickOutside = (e) => {
    if (menuRef.current.contains(e.target)) {
      return;
    }
    setOpen(false, e);
  };

  const handleScroll = () => {
    if (typeof window === `undefined`) {
      return;
    }
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      const header = document.getElementById("header");
      header.classList.add(styles.header__navScrolled);
    } else {
      const header = document.getElementById("header");
      header.classList.remove(styles.header__navScrolled);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });

  const { allShopJson, logo } = useStaticQuery(graphql`
    query {
      ...HeaderLogoQuery
      allShopJson(sort: { fields: priority, order: DESC }) {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <header id="navbar" className={styles.header}>
      <hr className={styles.header__trim} />
      <nav id="header" className={styles.header__nav}>
        <Link className={styles.header__logo} to="/">
          <Img
            style={{ height: "100%" }}
            loading="eager"
            fixed={logo.headerLogo.childImageSharp.fixed}
            fadeIn={false}
            objectFit="contain"
            alt="Lunsford Logo"
          />
        </Link>
        <div id="menu" ref={menuRef} className={styles.header__menu}>
          <div className={`${styles.header__link} ${styles.header__linkHome}`}>
            <Link onClick={(e) => setOpen(false)} to="/">
              HOME
            </Link>
            <button
              type="button"
              aria-label="Close Menu"
              onClick={(e) => setOpen(false, e)}
            >
              <CloseIcon className={styles.header__menuicon} />
            </button>
          </div>
          <div
            className={`${styles.header__dropwrapper} ${styles.header__linkScalable}`}
          >
            <Link
              to="/shop"
              onClick={(e) => setOpen(false)}
              activeClassName={styles.header__linkActive}
              className={`${styles.header__link} ${styles.header__linkShop}`}
            >
              SHOP
            </Link>
            <nav className={styles.header__dropdown}>
              {allShopJson.edges.map((edge) => {
                return (
                  <Link
                    to={`/shop/${edge.node.slug}`}
                    key={edge.node.slug}
                    onClick={(e) => setOpen(false)}
                    activeClassName={styles.header__linkActive}
                    className={styles.header__link}
                  >
                    {edge.node.title.toUpperCase()}
                  </Link>
                );
              })}
            </nav>
          </div>
          <Link
            to="/about"
            onClick={(e) => setOpen(false)}
            activeClassName={styles.header__linkActive}
            className={`${styles.header__link} ${styles.header__linkScalable}`}
          >
            ABOUT
          </Link>
          <Link
            to="/contact"
            onClick={(e) => setOpen(false)}
            activeClassName={styles.header__linkActive}
            className={`${styles.header__link} ${styles.header__linkScalable}`}
          >
            CONTACT
          </Link>
        </div>
        <MenuIcon
          className={`${styles.header__menuicon} ${styles.header__menuiconOpen}`}
          onClick={(e) => setOpen(true, e)}
        />
      </nav>
    </header>
  );
}

// All units rem
module.exports = {
  SIDE_WIDTH: "3rem",
  TOP_TRIM_HEIGHT: "1.5rem",
  SMALL_TOP_TRIM_HEIGHT: "0.75rem",
  COPPER: "#C98049",
  GREY: "#E4E4E4",
  LIGHT_GREY: "#ECEAEA",
  HEADER_HEIGHT: "9rem",
  SMALL_HEADER_HEIGHT: "3rem",
  MOBILE_FIX: "var(--vh, 1vh) * 100",
};

import React from "react";

function Insta({ className }) {
  return (
    <svg viewBox="0 0 69 69" fill="none" className={className}>
      <g clipPath="url(#clip0)">
        <path
          d="M0 34.478C0 15.318 15.356 0 34.533 0A34.359 34.359 0 0169 34.522c-.022 19.182-15.333 34.456-34.533 34.456C15.31 69 0 53.659 0 34.478z"
          fill="#000"
        />
        <path
          d="M17.6 34.456c0-2.531-.133-5.084.022-7.593.356-5.284 3.511-8.747 8.778-9.058 5.378-.31 10.8-.31 16.2 0 5.244.31 8.489 3.819 8.711 9.125.2 5.061.222 10.145 0 15.207-.222 5.373-3.622 8.836-9.022 9.102-5.222.267-10.467.267-15.689 0-5.378-.288-8.6-3.752-8.956-9.168-.177-2.531-.044-5.084-.044-7.615zm16.711 13.72c2.533 0 5.067.11 7.6-.023 3.6-.177 5.867-1.998 6.045-5.55.288-5.372.288-10.79 0-16.162-.2-3.619-2.423-5.506-6-5.595a279.083 279.083 0 00-14.934 0c-3.6.09-5.844 1.932-6.044 5.55-.311 5.373-.334 10.79 0 16.163.222 3.73 2.489 5.483 6.244 5.617 2.356.088 4.711.022 7.09 0z"
          fill="#C98049"
        />
        <path
          d="M34.533 43.114c-4.888.022-8.755-3.708-8.866-8.48-.111-4.84 3.866-8.792 8.844-8.77 4.845 0 8.711 3.84 8.734 8.614.022 4.773-3.867 8.614-8.712 8.636zm5.445-8.503c.044-3.22-2.289-5.661-5.467-5.683-3.155-.023-5.578 2.397-5.578 5.594 0 3.086 2.29 5.462 5.356 5.55 3.156.09 5.645-2.309 5.689-5.461zM43.044 27.04a1.91 1.91 0 10.002-3.82 1.91 1.91 0 00-.002 3.82z"
          fill="#C98049"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#C98049" d="M0 0H69V69H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Insta;

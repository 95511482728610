import "./src/style/global.css";

import React from "react";

import Modal from "react-modal";

import ShopifyBuy from "@shopify/buy-button-js";
import replaceComponentRenderer from "./replaceComponentRenderer";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  window.shopify = ShopifyBuy.UI.init(
    ShopifyBuy.buildClient({
      domain: "lunsford-ridge-farm.myshopify.com",
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_KEY,
    })
  );
  window.onload = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
};

export const onInitialClientRender = () => {
  Modal.setAppElement("#___gatsby");
  Modal.defaultStyles.content = {};
  Modal.defaultStyles.overlay = {};
};

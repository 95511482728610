import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import FacebookIcon from "../icons/FacebookIcon";
import InstaIcon from "../icons/InstaIcon";
import styles from "./Footer.module.css";

export default function Footer(props) {
  const { copyright, contact, logo } = useStaticQuery(graphql`
    query {
      ...ContactMetadataQuery
      ...CopyrightMetadataQuery
      ...FooterLogoQuery
    }
  `);
  return (
    <footer className={styles.footer}>
      <div className={styles.text}>
        <div className={styles.left}>
          <Img
            className={styles.image}
            loading="eager"
            fixed={logo.footerLogo.childImageSharp.fixed}
            fadeIn={false}
            objectFit="contain"
            alt="Lunsford Logo"
          />
          <div className={styles.info}>
            <div>{copyright.name}</div>
            <hr className={styles.border} />
            <div className={styles.address}>{contact.address}</div>
            <div className={styles.contact}>
              <a href={`tel:${contact.phone}`}>
                Phone:&nbsp;
                {contact.phone}
              </a>
              <a href={`mailto:${contact.email}`}>
                Email:&nbsp;
                {contact.email}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.iconwrapper}>
          <a href={contact.facebook}>
            <span className="aria-text">Facebook</span>
            <FacebookIcon />
          </a>
          <a href={contact.instagram}>
            <span className="aria-text">Instagram</span>
            <InstaIcon />
          </a>
        </div>
      </div>
      <iframe
        title="Google Maps Lunsford Ridge"
        className={styles.maps}
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBYC2MBuMP21SK6yvM6MtT2tyi0mpD60dQ&zoom=9&q=Lunsford+Ridge+Farm,+LLC"
        allowFullScreen
      />
    </footer>
  );
}

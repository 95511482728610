import React from "react";

function Menu({ className, onClick }) {
  return (
    <svg viewBox="0 0 44 27" className={className} onClick={onClick}>
      <path d="M0 1h44M0 13h44M0 25h44" />
    </svg>
  );
}

export default Menu;
